import { ComponentType } from 'react';
import { Stack } from '@mui/material';

import { CircularProgressStyled, PageLoaderSpinner } from '/shared/Spinner';

import { FCWithChildren } from './models';

interface HOCProps {
  isLoading: boolean | undefined;
  alignItems?: string;
  size?: number;
}
export const SpinnerProgress = ({ alignItems = 'center', size = 24 }: Omit<HOCProps, 'isLoading'>) => (
  <Stack spacing={1} justifyContent='center' alignItems={alignItems} role='progressbar'>
    <CircularProgressStyled size={size} />
  </Stack>
);
export const withLoading = <T extends object>(Component: ComponentType<T>) => {
  return (props: React.ComponentProps<ComponentType<T>> & HOCProps) => {
    const { isLoading, ...rest } = props;
    return isLoading ? <PageLoaderSpinner /> : <Component {...(rest as T)} />;
  };
};
export const Loading: FCWithChildren<HOCProps> = ({ children, isLoading, alignItems, size }) => {
  return isLoading ? <SpinnerProgress alignItems={alignItems} size={size} /> : <>{children}</>;
};
