import { useCallback, useState, MouseEvent } from 'react';
import { Button, Checkbox, List, ListItem, ListItemText, Popover } from '@mui/material';

import { AppEntityClass } from '@playq/octopus2-apps';

import { useModal } from '/hooks';

import { SelectEntitiesActions } from './styles';

/**
 * Parameters for the UsePromotionsEntitiesPopover hook.
 *
 * @property availableEntitiesToCopy - A record containing arrays of entities that can be copied.
 * @property onApply - Callback function invoked when entities are applied.
 * @param onApply.entitiesToCopy - The array of entities to copy.
 * @param onApply.source - SpaceID.id or Environment as the source identifier.
 */
export type UsePromotionsEntitiesPopoverParams = {
  availableEntitiesToCopy: Record<string, AppEntityClass[]>;
  onApply: (entitiesToCopy: AppEntityClass[], source: string) => void;
};

export const usePromotionsEntitiesPopover = ({
  availableEntitiesToCopy,
  onApply,
}: UsePromotionsEntitiesPopoverParams) => {
  const { anchorEl, openAnchorEl, closeAnchorEl } = useModal();

  const [entitiesToCopy, setEntitiesToCopy] = useState<AppEntityClass[]>([]);
  const [source, setSource] = useState<string>();

  const handleToggleEntityToCopy = (entity: AppEntityClass) => () => {
    setEntitiesToCopy((prevEntities) =>
      prevEntities.includes(entity) ? prevEntities.filter((e) => e !== entity) : [...prevEntities, entity]
    );
  };

  const handleClearEntitiesToCopy = () => {
    setEntitiesToCopy([]);
    closeAnchorEl();
  };

  const handleApplyEntitiesToCopy = () => {
    if (entitiesToCopy.length > 0 && source !== undefined) {
      onApply(entitiesToCopy, source);
      closeAnchorEl();
    }
  };

  const openEntitiesToCopySelect = useCallback(
    (event: MouseEvent<HTMLButtonElement>, newSource: string) => {
      setSource(newSource);
      openAnchorEl(event);
    },
    [openAnchorEl]
  );

  const Node =
    source !== undefined ? (
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClearEntitiesToCopy}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {availableEntitiesToCopy[source].map((entity) => (
            <ListItem key={entity} dense={true} button={true} onClick={handleToggleEntityToCopy(entity)}>
              <Checkbox checked={entitiesToCopy.includes(entity)} disableRipple={true} tabIndex={-1} />
              <ListItemText primary={entity} />
            </ListItem>
          ))}
        </List>
        <SelectEntitiesActions>
          <Button onClick={handleClearEntitiesToCopy}>Cancel</Button>
          <Button variant='contained' onClick={handleApplyEntitiesToCopy}>
            Apply
          </Button>
        </SelectEntitiesActions>
      </Popover>
    ) : null;

  return {
    PromotionsEntitiesPopover: Node,
    entitiesToCopy,
    openEntitiesToCopySelect,
    closeEntitiesToCopySelect: handleClearEntitiesToCopy,
  };
};
