import { FC, SyntheticEvent, useCallback, useMemo, useEffect } from 'react';
import { Stack } from '@mui/material';

import {
  Space,
  SpacesFilterField,
  SpacesFilterFieldHelpers,
  SpacesSort,
  SpacesSortField,
  SpacesSortFieldHelpers,
} from '@playq/octopus2-apps';
import { TextFilter } from '@playq/octopus-common';

import { CollectionTable, ICollectionTableQuery, ICollectionTableQueryChange } from '/shared/Table';
import { ICurrentApp, createSpaceMutationKey, deleteSpaceMutationKey, updateSpaceMutationKey } from '/api';
import { UserName } from '/shared/UserName';
import { FavoriteButton } from '/shared/AppAndSpaceFavoriteButton';

import { ISpaceTableProps, SpacesColumnType } from './types';
import { CheckCircleIcon, SpaceName } from './styles';

export const SpacesTable: FC<ISpaceTableProps> = (props) => {
  const {
    activeSpaceID,
    features,
    query,
    entitiesProcessing,
    pending,
    entities,
    total,
    queryKeys,
    actions,
    styles,

    setEnableQuerySpaces,
    onQueryChange,
    onEditSpace,
    onSelectSpace,
    refetch,
    isHidden,
    onSetColumns,
    onClose,
  } = props;

  const columns: SpacesColumnType[] = useMemo(
    () => [
      {
        label: 'Name',
        sort: SpacesSortField.Name,
        filter: SpacesFilterField.Name,
        filterType: TextFilter.ClassName,
        hidden: isHidden('Name'),
        render: (space: Space) => {
          const handleSelect = (e: SyntheticEvent) => {
            e.stopPropagation();
            onSelectSpace(space);
          };
          const isFavourite = features[space.id.serialize()]?.isFavourite;
          return (
            <Stack direction='row' alignItems='center'>
              <FavoriteButton
                currentApp={ICurrentApp.SPACE}
                isFavourite={isFavourite}
                app={space}
                queryKey={queryKeys}
              />
              <SpaceName onClick={handleSelect}>{space.name}</SpaceName>
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {space.id && activeSpaceID && space.id.id === activeSpaceID.id && <CheckCircleIcon />}
            </Stack>
          );
        },
      },
      {
        label: 'Description',
        hidden: isHidden('Description'),
        render: (space: Space) => {
          return space.description;
        },
      },
      {
        label: 'Created By',
        sort: SpacesSortField.AuthorName,
        filter: SpacesFilterField.AuthorName,
        filterType: TextFilter.ClassName,
        hidden: isHidden('Created By'),
        render: (space: Space) => <UserName id={space.createdBy} isModal={true} onClose={onClose} />,
      },
      // TODO remove/uncomment after product validation testing (PVT)
      // Should the client wish to add the functionality, you can uncomment the code
      // {
      //   label: 'Favorites',
      //   sort: SpacesSortField.Favourites,
      //   filter: SpacesFilterField.Favourites,
      //   filterType: BooleanFilter.ClassName,
      //   render: (space: Space) => {
      //     const isFavourite = features[space.id.serialize()]?.isFavourite;
      //     return <AppFavoriteButton currentApp={ICurrentApp.SPACE} isFavourite={isFavourite} app={space} queryKey={queryKeys}/>
      //   },
      // },
    ],
    [activeSpaceID, features, isHidden, onClose, onSelectSpace, queryKeys]
  );

  useEffect(() => {
    onSetColumns(columns);
    // no need to pass the onSetColumns to deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const isSpaceProcessing = useCallback((space: Space) => !!entitiesProcessing?.[space.id.id], [entitiesProcessing]);

  const handleRowClick = useCallback(
    (_e: SyntheticEvent, space: Space) => {
      if (isSpaceProcessing(space)) {
        return;
      }
      onEditSpace(space);
    },
    [isSpaceProcessing, onEditSpace]
  );

  function getRowKey(item: Space): string {
    return item.id.id;
  }

  function onTableQueryChange(q: ICollectionTableQueryChange<ICollectionTableQuery<SpacesSort>>) {
    onQueryChange(q.query);
  }

  return (
    <CollectionTable<Space, SpacesSortField, SpacesFilterField, SpacesSort, ICollectionTableQuery<SpacesSort>>
      pending={pending}
      columns={columns}
      actions={actions}
      getRowKey={getRowKey}
      initialQuery={query}
      data={entities}
      total={total}
      queryKeys={queryKeys}
      mutationsKeys={{
        currentPage: [createSpaceMutationKey, updateSpaceMutationKey],
        restPages: [createSpaceMutationKey, updateSpaceMutationKey, deleteSpaceMutationKey],
      }}
      options={{ withToolbar: false }}
      styles={styles}
      sortHelper={SpacesSortFieldHelpers}
      filterHelper={SpacesFilterFieldHelpers}
      sortClass={SpacesSort}
      onQueryChange={onTableQueryChange}
      refetch={refetch}
      onRowClick={handleRowClick}
      setEnableQueryEntities={setEnableQuerySpaces}
    />
  );
};
