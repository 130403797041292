import { SxProps, Tooltip, TooltipProps, Typography, styled, Theme } from '@mui/material';

import { Environment } from '@playq/octopus-common';

export const customTooltipSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});

export const CustomTooltip = styled((props: TooltipProps) => (
  <Tooltip disableInteractive={true} {...props} classes={{ popper: props.className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    ...customTooltipSx(theme),
  },
}));

interface IPromotionsCustomTooltipProps {
  name: string;
}

const getTooltipCopySourceTitle = (name: string) =>
  Object.values(Environment).includes(name as Environment) ? `from ${name}` : '';

const getTooltipUpdateSourceTitle = (name: Environment) =>
  Object.values(Environment).includes(name) ? `with ${name} promotions` : '';

export const UdpatePromotionsTooltipTitle = ({ name }: IPromotionsCustomTooltipProps) => (
  <>
    <Typography variant='h6'>Merge {getTooltipUpdateSourceTitle(name as Environment)}</Typography>
    <Typography>
      Promote all the entities from <b>{name}</b> to selected space without demoting.
    </Typography>
  </>
);

export const CopyPromotionsTooltipTitle = ({ name }: IPromotionsCustomTooltipProps) => (
  <>
    <Typography variant='h6'>Copy {getTooltipCopySourceTitle(name)}</Typography>
    <Typography>
      Demote all the entities of selected types{' '}
      <i>
        (for example: <b>Experiments</b>, <b>Game Events</b>, etc.)
      </i>{' '}
      from selected space and then promote all the entities of selected types from <b>{name}</b> to selected space.
    </Typography>
  </>
);

export const ClonePromotionsTooltipTitle = ({ name }: IPromotionsCustomTooltipProps) => (
  <>
    <Typography variant='h6'>Clone All {getTooltipCopySourceTitle(name)}</Typography>
    <Typography>
      Demote all the entities from selected space and then promote all the entities from <b>{name}</b> to selected
      space.
    </Typography>
  </>
);
