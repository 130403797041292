import { useCallback, useState, MouseEvent, useMemo, SyntheticEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, SxProps, Typography } from '@mui/material';
import {
  FileCopy as CopyAllIcon,
  Difference as CopyIcon,
  Merge as UpdateIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import { Environment, SpaceID } from '@playq/octopus-common';
import { Space, AppEntityClass } from '@playq/octopus2-apps';
import { AppPromotions, SpacePromotions } from '@playq/octopus2-apps-utility';

import { services2, unwrapEither } from '/api';
import { appToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { ConfirmDialogType, confirmDialog } from '/common/ConfirmDialog';
import { ITableRowAction } from '/shared/Table';
import { createPromotionSource, throwGenericFailure } from '/helpers';

import { useSpacesList } from '../useSpacesList';

import { getAvailableEntitiesToCopy } from './helpers';
import { usePromotionsEntitiesPopover } from './usePromotionsEntitiesPopover';
import {
  ClonePromotionsTooltipTitle,
  CopyPromotionsTooltipTitle,
  UdpatePromotionsTooltipTitle,
  customTooltipSx,
} from './CustomTooltip';
import { liveIconSx, sandboxIconSx } from './styles';

export type UseSpacesTableActionsParams = {
  setCurrentSpaceID: (spaceID?: SpaceID) => void;
  setSpacePromotions: (promotions?: SpacePromotions) => void;
};

export const useSpacesTableActions = ({
  setCurrentSpaceID,
  setSpacePromotions,
}: UseSpacesTableActionsParams): {
  actions: ITableRowAction<Space>[];
  PromotionsEntitiesPopover: JSX.Element | null;
} => {
  const appID = useSelector(appToolkit.selectors.appID);

  const [targetSpaceID, setTargetSpaceID] = useState<SpaceID>();

  const {
    sandboxPromotions,
    livePromotions,
    deleteSpace,
    isSpaceProcessing,
    clonePromotions,
    copyPromotions,
    updatePromotions,
  } = useSpacesList();

  const didCancel = useRef(false);

  useEffect(
    () => () => {
      didCancel.current = true;
    },
    []
  );

  const handleDelete = useCallback(
    (space: Space) => {
      setCurrentSpaceID(space.id);
      services2.spacesPromotionsService.spacePromotions(space.id).then((data) =>
        data.bifold((res) => {
          if (didCancel.current) {
            return;
          }
          if (res.byApp.length === 0) {
            confirmDialog({
              title: `DELETE ${space.name}?`,
              text: `Are you sure you want to delete the ${space.name}?`,
              closeButton: { label: 'NO' },
              successButton: { label: 'YES' },
              onSuccess: () => deleteSpace(space.id),
            });
          } else {
            setSpacePromotions(res);
          }
        }, throwGenericFailure)
      );
    },
    [setCurrentSpaceID, setSpacePromotions, deleteSpace]
  );

  const handleClone = useCallback(
    (env: Environment) => (_: SyntheticEvent, space: Space) => {
      if (appID) {
        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'CLONE',
          text: (
            <>
              <Typography>
                This action will demote all entities from target space and then promote entities from {env}. If you want
                to promote entities without demoting existing, use &quot;Merge Promotions&quot; action. Make sure you
                have write permissions to all of the kinds of promoted entities.
              </Typography>
              <Typography>Are you sure you want to proceed?</Typography>
            </>
          ),
          onSuccess: () => clonePromotions({ appID, source: createPromotionSource(env), targetSpaceID: space.id }),
        });
      }
    },
    [appID, clonePromotions]
  );

  const handleUpdate = useCallback(
    (promotions: AppPromotions, env: Environment) => async (_: SyntheticEvent, space: Space) => {
      if (!appID || !sandboxPromotions) {
        return;
      }
      const spaceID = space.id;
      const source = createPromotionSource(spaceID);
      try {
        const targetPromotions = await unwrapEither(services2.spacesPromotionsService.appPromotions(appID, source));
        const updatedPromotions = new AppPromotions(targetPromotions.serialize());
        if (promotions.config !== undefined) {
          updatedPromotions.config = promotions.config;
        }
        if (promotions.segment !== undefined) {
          updatedPromotions.segment = promotions.segment;
        }
        updatedPromotions.experiments.push(...promotions.experiments);
        updatedPromotions.events.push(...promotions.events);
        updatedPromotions.flows.push(...promotions.flows);

        confirmDialog({
          type: ConfirmDialogType.Warning,
          title: 'MERGE PROMOTIONS',
          text: `Are you sure you want to promote all ${env} entities to ${space.name}?  Make sure you have write permissions to all of the kinds of promoted entities.`,
          onSuccess: () => updatePromotions({ spaceID, promotions: updatedPromotions }),
        });
      } catch (err) {
        snackbarService.genericFailure(err as Error);
      }
    },
    [appID, updatePromotions, sandboxPromotions]
  );

  const handleCopy = useCallback(
    (entitiesToCopy: AppEntityClass[], env: string) => {
      if (!appID || !targetSpaceID) {
        return;
      }
      confirmDialog({
        type: ConfirmDialogType.Warning,
        title: 'COPY',
        text: (
          <>
            <Typography>
              This action will demote all selected entities from target space and then promote selected entities. Make
              sure you have write permissions to all of the kinds of selected entities.
            </Typography>
            <Typography>Are you sure you want to proceed?</Typography>
          </>
        ),
        onSuccess: () =>
          copyPromotions({
            appID,
            source: createPromotionSource(env as Environment),
            targetSpaceID,
            entities: entitiesToCopy,
          }),
      });
    },
    [appID, targetSpaceID, copyPromotions]
  );

  const availableEntitiesToCopy = useMemo(
    () => ({
      [Environment.Sandbox]: getAvailableEntitiesToCopy(sandboxPromotions),
      [Environment.Live]: getAvailableEntitiesToCopy(livePromotions),
    }),
    [sandboxPromotions, livePromotions]
  );

  const { PromotionsEntitiesPopover, openEntitiesToCopySelect } = usePromotionsEntitiesPopover({
    availableEntitiesToCopy,
    onApply: handleCopy,
  });

  const handleCopySandboxIconClick = useCallback(
    (event: SyntheticEvent, space: Space) => {
      setTargetSpaceID(space.id);
      openEntitiesToCopySelect(event as MouseEvent<HTMLButtonElement>, Environment.Sandbox);
    },
    [openEntitiesToCopySelect]
  );

  const handleCopyLiveIconClick = useCallback(
    (event: SyntheticEvent, space: Space) => {
      setTargetSpaceID(space.id);
      openEntitiesToCopySelect(event as MouseEvent<HTMLButtonElement>, Environment.Live);
    },
    [openEntitiesToCopySelect]
  );

  const tooltipProps = useMemo(() => ({ componentsProps: { tooltip: { sx: customTooltipSx as SxProps } } }), []);

  const actions: ITableRowAction<Space>[] = useMemo(
    () => [
      {
        icon: () => (
          <IconButton size='small'>
            <CopyIcon sx={sandboxIconSx} />
          </IconButton>
        ),
        label: 'Copy From Sandbox',
        tooltip: <CopyPromotionsTooltipTitle name={Environment.Sandbox} />,
        tooltipProps,
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        size: 'small',
        onClick: handleCopySandboxIconClick,
      },
      {
        icon: () => (
          <IconButton size='small'>
            <CopyIcon sx={liveIconSx} />
          </IconButton>
        ),
        tooltip: <CopyPromotionsTooltipTitle name={Environment.Live} />,
        tooltipProps,
        label: 'Copy From Live',
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !livePromotions,
        size: 'small',
        onClick: handleCopyLiveIconClick,
      },
      {
        label: 'Merge With Sandbox Promotions',
        tooltip: <UdpatePromotionsTooltipTitle name={Environment.Sandbox} />,
        tooltipProps,
        icon: () => <UpdateIcon sx={sandboxIconSx} />,
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        onClick: handleUpdate(sandboxPromotions as AppPromotions, Environment.Sandbox),
      },
      {
        label: 'Merge With Live Promotions',
        tooltip: <UdpatePromotionsTooltipTitle name={Environment.Live} />,
        tooltipProps,
        icon: () => <UpdateIcon sx={liveIconSx} />,
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        onClick: handleUpdate(livePromotions as AppPromotions, Environment.Live),
      },
      {
        label: 'Clone All From Sandbox',
        tooltip: <ClonePromotionsTooltipTitle name={Environment.Sandbox} />,
        tooltipProps,
        icon: () => <CopyAllIcon sx={sandboxIconSx} />,
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        onClick: handleClone(Environment.Sandbox),
      },
      {
        label: 'Clone All From Live',
        tooltip: <ClonePromotionsTooltipTitle name={Environment.Live} />,
        tooltipProps,
        icon: () => <CopyAllIcon sx={liveIconSx} />,
        pending: (space: Space) => isSpaceProcessing(space),
        hidden: !sandboxPromotions,
        onClick: handleClone(Environment.Live),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        pending: (space: Space) => isSpaceProcessing(space),
        onClick: (_: SyntheticEvent, space: Space) => handleDelete(space),
      },
    ],
    [
      handleClone,
      handleCopySandboxIconClick,
      handleCopyLiveIconClick,
      handleDelete,
      handleUpdate,
      isSpaceProcessing,
      sandboxPromotions,
      livePromotions,
      tooltipProps,
    ]
  );

  return {
    actions,
    PromotionsEntitiesPopover,
  };
};
