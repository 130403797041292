import { Box } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import { styled } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import { sandboxColor, liveColor } from '/shared/Promotions';

export const CheckCircleIcon = styled(CheckCircle)`
  margin-left: 5px;
  vertical-align: middle;
  color: ${green[500]};
`;

export const SpaceName = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.mode === 'light' ? '#337ab7' : blue[400],
  '&:hover': {
    textDecoration: theme.palette.mode === 'light' ? 'none' : 'underline',
    color: theme.palette.mode === 'light' ? '#23527c' : blue[400],
  },
}));

export const SpacePromotionsHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectEntitiesActions = styled(Box)`
  display: flex;
  align-items: center;

  ${({ theme }) => `
    padding: ${theme.spacing(2)};
    gap: ${theme.spacing(1)};
  `};
`;

export const sandboxIconSx = {
  fill: sandboxColor,
};

export const liveIconSx = {
  fill: liveColor,
};
