import { useCallback, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Space } from '@playq/octopus2-apps';
import { Environment } from '@playq/octopus-common';

import {
  useAppSpacePromotionsCopy,
  useAppSpacePromotionsClone,
  useAppSpacePromotionsUpdate,
  useAppSpacePromotions,
} from '/api';

export const useSpacesListPromotionsActions = (entitiesProcessing?: Record<string, boolean>) => {
  const [isFetchingEnabled, setIsFetchingEnabled] = useState<Record<Environment, boolean>>({
    [Environment.Live]: false,
    [Environment.Sandbox]: false,
  });

  const queryClient = useQueryClient();

  const {
    data: sandboxPromotions,
    isFetching: isRetrievingSandbox,
    keys: sandboxKeys,
  } = useAppSpacePromotions(Environment.Sandbox, {
    staleTime: 10000,
    enabled: isFetchingEnabled.Sandbox,
  });

  const {
    data: livePromotions,
    isFetching: isRetrievingLive,
    keys: liveKeys,
  } = useAppSpacePromotions(Environment.Live, {
    staleTime: 10000,
    enabled: isFetchingEnabled.Live,
  });

  const cachedSandboxPromotions = queryClient.getQueryData(sandboxKeys);

  const cachedLivePromotions = queryClient.getQueryData(liveKeys);

  const { mutate: clonePromotions, isLoading: isCloning } = useAppSpacePromotionsClone();

  const { mutate: copyPromotions, isLoading: isCopying } = useAppSpacePromotionsCopy();

  const { mutate: updatePromotions, isLoading: isUpdating } = useAppSpacePromotionsUpdate();

  const isSpaceProcessing = useCallback(
    (space: Space) => {
      return (
        isRetrievingSandbox ||
        isRetrievingLive ||
        isCloning ||
        isCopying ||
        isUpdating ||
        !!entitiesProcessing?.[space.id.id]
      );
    },
    [entitiesProcessing, isRetrievingLive, isRetrievingSandbox, isCloning, isCopying, isUpdating]
  );

  useEffect(
    function enableFetchingIfNoCachedPromotions() {
      setIsFetchingEnabled({
        [Environment.Sandbox]: cachedSandboxPromotions === undefined,
        [Environment.Live]: cachedLivePromotions === undefined,
      });
    },
    [cachedSandboxPromotions, cachedLivePromotions]
  );

  return {
    sandboxPromotions,
    livePromotions,
    clonePromotions,
    copyPromotions,
    updatePromotions,
    isSpaceProcessing,
  };
};
