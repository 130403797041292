import { Component, createContext, PropsWithChildren, ReactNode } from 'react';
import { connect, InferableComponentEnhancerWithProps } from 'react-redux';
import { Dispatch } from 'redux';
import { createTheme, GlobalStyles, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { blue, common, orange, red } from '@mui/material/colors';
import { PayloadAction } from '@reduxjs/toolkit';
import { StyledEngineProvider } from '@mui/styled-engine';

import { RootState, uiToolkit } from '/store';
import { preferredThemeModeLocalStorageKey } from '/constants';
import { setThemeModeStylingMetadata } from '/helpers';

import {
  IThemeModeContext,
  IThemeProviderDispatchProps,
  IThemeProviderState,
  IThemeProviderStateProps,
  ThemeMode,
} from './models';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const inputLabelOverflowHeight = 5;
const globalCustomStyles = {
  '*': {
    '-webkit-tap-highlight-color': 'transparent',
  },
};
const cardPadding = 15;
const commonWhite: string = common.white;
const palettePrimary = {
  main: blue[700],
};
const paletteSecondary = {
  main: '#f50057',
  dark: '#c51162',
};

const sandboxColor = orange[500];
const liveColor = red[500];

const defaultBackgroundColor = '#fafafa';

export const lightTheme = createTheme({
  palette: {
    mode: ThemeMode.Light,
    primary: palettePrimary,
    secondary: paletteSecondary,
    background: {
      paper: commonWhite,
      default: defaultBackgroundColor,
    },
    promotion: {
      sandbox: sandboxColor,
      live: liveColor,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'unset',
          backgroundImage: 'none',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: cardPadding,
          '.MuiCardHeader-action': {
            margin: 0,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: cardPadding,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          margin: 0,
        },
        displayedRows: {
          margin: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&+.MuiDialogContent-root': {
            paddingTop: inputLabelOverflowHeight,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            pointerEvents: 'auto',
            '&:hover': {
              backgroundColor: 'inherit',
            },
          },
        },
      },
    },
  },
});

export const textSecondaryDarkThemeColor = 'rgba(255, 255, 255, .7)';
export const textDisabledDarkThemeColor = 'rgba(255, 255, 255, .5)';
export const actionFocusDarkThemeColor = 'rgba(255, 255, 255, 0.12)';

export const darkPaperBackground = '#303030';
export const darkDefaultBackground = '#1c2127';
export const selectedCheckColor = '#008000';

export const darkTheme: Theme = createTheme({
  ...lightTheme,
  // overriding the light theme with the next palette:
  palette: {
    primary: palettePrimary,
    secondary: paletteSecondary,
    error: {
      main: red.A200,
    },
    mode: ThemeMode.Dark,
    text: {
      primary: commonWhite,
      secondary: textSecondaryDarkThemeColor,
      disabled: textDisabledDarkThemeColor,
    },
    background: {
      paper: darkPaperBackground,
      default: darkDefaultBackground,
    },
    action: {
      active: commonWhite,
      hover: 'rgba(255, 255, 255, 0.05)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.13)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledOpacity: 0.38,
      disabledBackground: actionFocusDarkThemeColor,
      focus: actionFocusDarkThemeColor,
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    promotion: {
      sandbox: sandboxColor,
      live: liveColor,
    },
  },

  components: {
    ...lightTheme.components,
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: commonWhite,
        },
      },
    },
    MuiOutlinedInput: {
      ...lightTheme.components?.MuiOutlinedInput,
      styleOverrides: {
        root: {
          '&.Mui-focused $notchedOutline': {
            borderColor: commonWhite,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: commonWhite,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: () => ({
          backgroundColor: 'rgb(60, 60, 60)',
        }),
      },
    },
  },
});

const getInitialThemeMode = (): ThemeMode => {
  const targetThemeMode = localStorage.getItem(preferredThemeModeLocalStorageKey) as ThemeMode | null;

  return !targetThemeMode || !Object.values(ThemeMode).includes(targetThemeMode) ? ThemeMode.Light : targetThemeMode;
};

const initialThemeModeContext = {
  currentThemeMode: getInitialThemeMode(),
  themesAvailable: {
    [ThemeMode.Light]: lightTheme,
    [ThemeMode.Dark]: darkTheme,
  },
  getCurrentTheme() {
    return this.themesAvailable[this.currentThemeMode];
  },
};

export const ThemeModeContext = createContext<IThemeModeContext>(initialThemeModeContext as IThemeModeContext);

export class ThemeProvider extends Component<
  PropsWithChildren<IThemeProviderStateProps & IThemeProviderDispatchProps>,
  IThemeProviderState
> {
  private readonly setThemeMode: (themeMode: ThemeMode) => PayloadAction<ThemeMode>;

  constructor(props: IThemeProviderStateProps & IThemeProviderDispatchProps) {
    super(props);

    this.setThemeMode = props.setThemeMode;

    this.initThemeProvider();

    this.state = { currentThemeMode: getInitialThemeMode() };
  }

  static getAppropriateStyles<T extends string | number | Record<string, unknown>>(
    themeMode: ThemeMode,
    lightModeCaseStyles: T,
    darkModeCaseStyles: T
  ): T {
    if (themeMode === ThemeMode.Light) {
      return lightModeCaseStyles;
    }

    if (themeMode === ThemeMode.Dark) {
      return darkModeCaseStyles;
    }

    return lightModeCaseStyles;
  }

  updateThemeMode = (themeMode: ThemeMode) => {
    this.setState({ currentThemeMode: themeMode });
    setThemeModeStylingMetadata(themeMode);
    this.setThemeMode(themeMode);
  };

  private initThemeProvider() {
    const targetThemeMode: ThemeMode = getInitialThemeMode();

    setThemeModeStylingMetadata(targetThemeMode);
  }

  render() {
    const { currentThemeMode } = this.state;
    const themeModeContext: IThemeModeContext = {
      currentThemeMode,
      updateThemeMode: this.updateThemeMode,
      themesAvailable: {
        [ThemeMode.Light]: lightTheme,
        [ThemeMode.Dark]: darkTheme,
      },

      getCurrentTheme() {
        return this.themesAvailable[this.currentThemeMode];
      },
    };

    const theme: Theme = themeModeContext.themesAvailable[currentThemeMode];

    return (
      <ThemeModeContext.Provider value={themeModeContext}>
        <StylesProvider injectFirst>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <GlobalStyles styles={globalCustomStyles} />
              {this.props.children}
            </MuiThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </ThemeModeContext.Provider>
    );
  }
}

const mapStateToProps = (state: RootState): IThemeProviderStateProps => ({
  currentThemeMode: uiToolkit.selectors.currentThemeMode(state),
});
const mapDispatchToProps = (dispatch: Dispatch): IThemeProviderDispatchProps => ({
  setThemeMode: (mode: ThemeMode) => dispatch(uiToolkit.actions.setCurrentThemeMode(mode)),
});

export default (
  connect(mapStateToProps, mapDispatchToProps) as InferableComponentEnhancerWithProps<
    IThemeProviderStateProps & IThemeProviderDispatchProps,
    { children?: ReactNode }
  >
)(ThemeProvider);
